
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getInstance } from "./plugins/auth";

@Component
export default class Main extends Vue {
  signOutFab: boolean = false;
  auth: any = getInstance();

  get signedIn() {
    return this.auth.isAuthenticated;
  }

  @Watch("signedIn")
  onSignedInChanged() {
    this.signOutFab = false;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  signOut(): void {
    this.$store.dispatch("resetAllStores");
    this.auth.logout();
  }

  get copyrightYear() {
    return new Date().getFullYear();
  }
}
