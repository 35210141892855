/* eslint-disable no-console */
import Vue from "vue";
import createAuth0Client, {
  Auth0Client,
  IdToken,
  User
} from "@auth0/auth0-spa-js";
import { initializeAfterSignIn } from "@/util/authentication";
import store from "@/store";
import AppUser from "@/model/app-user";
import LogRocket from "logrocket";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (appState?: any) =>
  window.history.replaceState(
    appState,
    document.title,
    window.location.pathname
  );

const mapUser = (auth0User: User): AppUser => {
  // Identify user to logrocket
  LogRocket.identify(auth0User.sub || "", {
    name: auth0User.name || "",
    email: auth0User.email || ""
  });

  return {
    name: auth0User.name,
    email: auth0User.email,
    email_verified: auth0User.email_verified,
    nickname: auth0User.nickname,
    picture: auth0User.picture,
    updated_at: auth0User.updated
  } as AppUser;
};

let instance: Vue;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
}) => {
  if (instance) return instance;

  let auth0Type: Auth0Client;
  let userType: AppUser;

  // The "instance" is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: userType,
        auth0Client: auth0Type,
        popupOpen: false,
        error: null
      };
    },
    methods: {
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o: any): Promise<void> {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o: any): Promise<IdToken | undefined> {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(): void | Promise<void> {
        return this.auth0Client.logout({
          returnTo: process.env.VUE_APP_AUTH0_REDIRECT
        });
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        client_id: process.env.VUE_APP_AUTH0_CLIENTID,
        audience: process.env.VUE_APP_API_URI,
        redirect_uri: process.env.VUE_APP_AUTH0_REDIRECT
      });

      let self = this;

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize the internal authentication state
        self.isAuthenticated = await self.auth0Client.isAuthenticated();
        const userResponse = await self.auth0Client.getUser();
        if (userResponse) {
          self.user = mapUser(userResponse);
        }
        self.loading = false;

        if (self.isAuthenticated) {
          const accessToken = await self.auth0Client.getTokenSilently({
            audience: process.env.VUE_APP_API_URI
          });
          
          // Set the authentication token
          if (accessToken) {
            store.commit( 'user/setAuth0AccessToken', accessToken );
          }
          initializeAfterSignIn(store, self.user);
        }
      }
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(
    Vue: { prototype: { $auth: Vue } },
    options: {
      onRedirectCallback?: ((appState?: any) => void) | undefined;
    }
  ) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
